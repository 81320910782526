import React from "react";
import MainSlider from "./MainSlider";
import { useEffect } from 'react';
import Cats from "./Cats";

function Home() {
    useEffect (() => {
        document.title = "Cake Shop in Dubai Marina & JLT | Online Cake Delivery Dubai";
    })
    return (
        <>
        <MainSlider/>
        <Cats />
        </>
    );
}
export default Home;