import React from "react";
import { useEffect } from "react";

function Categories() {
    useEffect (() => {
        document.title = "Product Categories";
    })
    return(
    <>
        <div className="bg aboutus padtop80 padbot80">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1>categories</h1><br />
                    </div>
                </div>
            </div>
        </div>
    </> 
    );
}
export default Categories;