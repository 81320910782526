import React from "react";
import { useEffect } from "react";
import aboutbg from './assets/images/product-categories-cake-social-bg.png';

function AboutUs() {
    useEffect (() => {
        document.title = "About Cake Social";
    })
    return(
    <>
        <div className="bg aboutus padtop80 padbot80" style={{ backgroundImage: `url(${aboutbg})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Welcome To Cake Social</h1><br />    
                        <p>The journey of Cake Social started with the purest of emotions- a commitment to living a joyful and happy life, to put a smile on people’s faces and make those extra special moments in life truly incredible. This is why we ventured into the world of cakes in 2019 and never looked back.</p>
                        <p>Cake Social is a family-run labor of love founded on our appreciation of food, art and socializing. We wanted to give our “Socials” something truly special, so we set out on a mission to bake delicious, stylish yet balanced cakes. Our cakes contain the finest ingredients sourced from different parts of the globe creating unique flavors and mouthwatering experiences that all cake lovers are sure to appreciate.</p>
                        <p>To make all this possible, we recruited an amazing team of experts, cake artists, chefs, and bakers. Their combined skills, talents, and passion for design has helped us create truly magical range of cakes and flavors.</p>      
                        <p>One of our most important kitchen rules is “make it with love!”, this influences our standards of quality and creativity which is our commitment to making our “socials” lives that much sweeter. This same dedication is shown to every client, project, or design we undertake. We want people to celebrate events, moments, and life as often as possible, and to ensure everyone gets to share in the experience, we’re actively developing our offering by moving into the healthy lines, keto, sugar-free, and gluten-free products.</p>
                        <p>To all our “Socials” welcome to our world and we can’t wait to make your moments even sweeter.</p>
                        <p><b>The next time you order a cake, make it a Cake Social experience!</b></p>
                        <p><b>With Love,<br/>Cake Social Family</b></p>
                    </div>
                </div>
            </div>
        </div>
    </> 
    );
}
export default AboutUs;