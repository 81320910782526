import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const ProductPage = () => {
    const { id } = useParams();
    const [product, setProduct] = useState(null);

    useEffect(() => {
        fetch('https://awaisasif.com/cakedatabase/')
            .then(response => response.json())
            .then(data => {
                const selectedProduct = data.find(prod => prod.product_id === id);
                setProduct(selectedProduct);
            })
            .catch(error => {
                console.error('Error fetching the product:', error);
            });
    }, [id]);

    if (!product) {
        return <div>Loading...</div>;
    }

    return (
        <div className="single-product">
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <img src={product.product_image} alt={product.product_title} />
                    </div>
                    <div className="col-md-7">
                        <div className="product-page">
                            <h1>{product.product_title}</h1>
                            <p>{product.product_description}</p>
                            <p>Price: AED {product.product_price}</p>
                            <a href="#0" className="btn">Add to Cart</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductPage;
