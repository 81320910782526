import { NavLink } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import banner1 from "./assets/images/Cakes-in-Dubai.jpg";
import banner2 from "./assets/images/Cakes-in-Dubai.jpg";

function MainSlider() {
  return (
        <div className='mainslider'>
                <Carousel fade={true} interval={null} controls={false} indicators={false}>
                    <Carousel.Item>
                        <img className="d-block w-100" src={banner1} alt="First slide" />
                        <Carousel.Caption className='text-start'>
                            <h1>Moments <br /> Made Sweeter </h1>
                            <p>Love, Live & Celebrate Life </p>
                            <NavLink to="/shop" className="slider-btn">order online</NavLink>
                        </Carousel.Caption>
                    </Carousel.Item>
                    
                    {/* <Carousel.Item>
                        <img className="d-block w-100" src={banner2} alt="Second slide" />
                        <Carousel.Caption className='text-start'>
                            <h1>Moments <br /> Made Sweeter </h1>
                            <p>Love, Live & Celebrate Life </p>
                            <a href="#0" className="slider-btn">order online</a>
                        </Carousel.Caption>
                    </Carousel.Item> */}

                </Carousel>
        </div>
  );
}

export default MainSlider;