import React from "react";
import logo from './assets/images/cake-social.png';
import accounticon from './assets/images/cake-social-account.webp';
import carticon from './assets/images/cake-social-cart.webp';
import fbicon from './assets/images/cake-social-facebook.webp';
import instaicon from './assets/images/cake-social-instagram.webp';
import { NavLink } from "react-router-dom";

function Header() {
    return(
       <>
       <header>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-5">
                        <ul className="cake-menu">
                            <li><NavLink className="nav-link" to="/" ><span>Home</span></NavLink></li>
                            <li><NavLink className="nav-link" to="/about-us" ><span>About Us</span></NavLink></li>   
                            <li><NavLink className="nav-link" to="/shop" ><span>Shop</span></NavLink></li>
                            {/* <li><a href="#0">Customize Your Cake</a></li> */}
                            <li className="menu-item"><a href="#0">Our Cakes</a>
                                <ul className="sub-menu">
                                    <li><NavLink className="nav-link" to="/categories" >Signature Cakes</NavLink></li>
                                    <li><NavLink className="nav-link" to="/categories" >Signature Bakes</NavLink></li>
                                    <li><NavLink className="nav-link" to="/categories" >Themed Cakes</NavLink></li>
                                    <li><NavLink className="nav-link" to="/categories" >Themed Cupcakes & Cakes</NavLink></li>
                                    <li><NavLink className="nav-link" to="/categories" >Wedding Cakes</NavLink></li>
                                    <li><NavLink className="nav-link" to="/categories" >Corporate Desserts</NavLink></li>
                                </ul>
                            </li>   
                            <li><NavLink className="nav-link" to="/Contact-us"><span>Contact Us</span></NavLink></li> 
                            <li><NavLink className="nav-link" to="/cart"><span>Cart</span></NavLink></li> 
                        </ul>  
                    </div>
                    <div className="col-md-2">
                        <div className="head-logo">
                            <NavLink className="nav-link" to="/" ><img src={logo} alt="Cake Social Logo" /></NavLink>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="headright">
                            <input type="text" name="search" placeholder="Search products..." />
                            <div className="social">
                                <a href="https://www.facebook.com/CakeSocialdxb/" target="_blank"rel="noreferrer">
                                    <img src={fbicon} alt="" />
                                </a>
                                <a href="https://www.instagram.com/cakesocialdxb/" target="_blank"rel="noreferrer">
                                    <img src={instaicon} alt="" />
                                </a>
                                <a href="#0">
                                    <img src={accounticon} alt="" />
                                </a>
                                <a href="#0">
                                    <img src={carticon} alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </header>
       </>
    );
}
export default Header;