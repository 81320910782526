import React from "react";
import { NavLink } from "react-router-dom";
import logo from './assets/images/cake-social.png';
import fb from './assets/images/cakesocial-facebook.png';
import insta from './assets/images/cakesocial-instagram.png';
import paymentlogo from './assets/images/cake-social-payments.png';

function Footer() {
    return(
      <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <NavLink className="nav-link" to="/" ><img src={logo} className="footer-logo" alt="Cake Social Logo" /></NavLink>
              <h3>NAVIGATION</h3>
              <div className="row">
                <div className="col-md-6">
                  <ul className="footermenu">
                    <li><NavLink className="nav-link" to="/about-us" ><span>About Us</span></NavLink></li>
                    <li><a href="#0">Shop</a></li>
                    <li><NavLink className="nav-link" to="/Contact-us"><span>Contact Us</span></NavLink></li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul>
                    <li><a href="#0">Signature Cakes</a></li>
                    <li><a href="#0">Birthday Cakes</a></li>
                    <li><a href="#0">Cupcakes & Bakes</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col md-6">
              <h3>Contact Details</h3>
              <ul className="contact-details">
                <li><a href="tel:+97145787020">+971 4 578 7020</a></li>
                <li><a href="tel:+971521080761">+971 52 108 0761</a></li>
                <li><a href="mailto:info@cakesocial.ae">info@cakesocial.ae</a></li>
                <li><p>R3-B Lake Point Tower, Cluster N, Jumeirah Lakes Towers, Dubai, UAE. P.O.Box 39999</p></li>
              </ul>
              <div className="cake-social">
                <a href="https://www.facebook.com/CakeSocialdxb/" target="_blank" rel="noreferrer"><img src={fb} alt="Cake social facebook" /></a> 
                <a href="https://www.instagram.com/cakesocialdxb/" target="_blank" rel="noreferrer"><img src={insta} alt="Cake social isntagram" /></a> 
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="footerbottom">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p>© 2022, Cake Social All rights Reserved</p>
            </div>
            <div className="col-md-6 payment-logo">
              <img src={paymentlogo} alt="cake social payment method"/> 
            </div>
          </div>
        </div>
      </div>
      </>
    );
}
export default Footer;