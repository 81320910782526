import './assets/fonts/Poppins-Regular.ttf';
import './assets/fonts/Poppins-Light.ttf';
import './assets/fonts/Poppins-Bold.ttf';
import Home from './Home';
import Footer from "./Footer";
import Header from "./Header";
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./Scroll";
import Shop from './Shop';
import Categories from './Categories';
import SingleProduct from './SingleProduct';

function App() {
  return (
    <BrowserRouter>
    <ScrollToTop />
      <Header />
        <div id="Inerweb">
          <Routes>
            <Route path='/' element={<Home />}></Route>
            <Route path='/about-us' element={<AboutUs />}></Route>
            <Route path='/shop' element={<Shop />}></Route>
            <Route path='/product/:id' element={<SingleProduct />}></Route>
            <Route path='/categories' element={<Categories />}></Route>
            <Route path='/contact-us' element={<ContactUs />}></Route>
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
  );
}
export default App;