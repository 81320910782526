import React from "react";
import loadinggif from './assets/images/loader.gif';

function Loader() {
    return(
    <>
        <div id="loader" style={{ textAlign: `center` }}>
            <img src={loadinggif}  />
        </div>
    </> 
    );
}
export default Loader;