import React from "react";
import { useEffect } from "react";
import aboutbg from './assets/images/product-categories-cake-social-bg.png';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

function ContactUs() {
    const [validated, setValidated] = useState(false);
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        }

        setValidated(true);
    };
    useEffect (() => {
        document.title = "Cake Social | Contact Details";
    }, 
    []);
    return(
    <>
        <div className="bg contactus padtop80 padbot80" style={{ backgroundImage: `url(${aboutbg})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <h1>Contact Details</h1><br />
                        <div className="contact-details">
                            <a href="mailto:info@cakesocial.ae">info@cakesocial.ae</a>
                            <a href="tel:0097145787020">+971 4 578 7020</a>
                            <a href="https://api.whatsapp.com/send?phone=971521080761&text=" target="_blank">+971 52 108 0761</a>
                            <h3>Let’s have a cup of coffee & a slice of cake</h3>
                            <h4>Cake Social DMCC</h4>  
                            <p>R3-B Lake Point Tower, Cluster N, Jumeirah Lakes Towers, Dubai, United Arab Emirates.<br /> P.O.Box 39999</p>       
                        </div>
                    </div>
                    <div className="col-md-7">
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Your Name</Form.Label>
                                    <Form.Control required type="text" placeholder="" />
                                    <Form.Control.Feedback type="invalid">please enter your name</Form.Control.Feedback>
                                    {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom02">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control required type="email" placeholder="" />
                                    <Form.Control.Feedback type="invalid">please enter your email</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control required type="text" placeholder="" />
                                    <Form.Control.Feedback type="invalid">please enter your phone</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Subject</Form.Label>
                                    <Form.Control type="text" placeholder="" />
                                </Form.Group>
                            </Row>
                            <Row className="mb-4">
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="" />
                                </Form.Group>
                            </Row>
                            <Button type="submit" className="btn">Submit</Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}
export default ContactUs;