import React, { useEffect, useState } from "react";
import Loader from "./Loader";
import { NavLink } from "react-router-dom";

function Shop() {
    useEffect(() => {
        document.title = "Shop";
    });

    const [isLoading, setIsLoading] = useState(false);
    const [productCount, setProductCount] = useState(0);
    const [visibleProducts, setVisibleProducts] = useState(12);
    const [showGoUpButton, setShowGoUpButton] = useState(false);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    const allowedCategories = [
        "Signature Cakes",
        "Themed Cakes",
        "Themed Cupcakes",
        "For Him",
        "For Her",
        "Christmas Desserts"
    ];

    useEffect(() => {
        setIsLoading(true);
        fetch('https://awaisasif.com/cakedatabase/')
            .then(response => response.json())
            .then(data => {
                setProducts(data);
                const uniqueCategories = [...new Set(data.map(product => product.product_categories))];
                const filteredCategories = uniqueCategories.filter(category => allowedCategories.includes(category));
                setCategories(filteredCategories);
                setProductCount(data.length);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching the products:', error);
                setIsLoading(false);
            });
    }, []);

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setVisibleProducts(12);
    };

    const handleResetClick = () => {
        setSelectedCategory(null);
        setVisibleProducts(12);
    };

    const loadMore = () => {
        setVisibleProducts(prevVisibleProducts => prevVisibleProducts + 12);
    };

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
    };

    const filteredProducts = selectedCategory 
        ? products.filter(product => product.product_categories === selectedCategory) 
        : products;

    const filteredAndSearchedProducts = filteredProducts.filter(product =>
        product.product_title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowGoUpButton(true);
            } else {
                setShowGoUpButton(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <>
            <div className="bg aboutus padtop80 padbot80">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>Shop</h1><br />
                            {isLoading ? <Loader /> : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <h3>Categories</h3>
                            <ul id="cates">
                                {categories.map((category, index) => (
                                    <li key={index}>
                                        <a className={selectedCategory === category ? 'selected' : ''} 
                                            onClick={() => handleCategoryClick(category)}>
                                            {category}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="col-md-9">
                            <h3>{selectedCategory ? `Products in "${selectedCategory}"` : 'All Products'}</h3>
                            <input
                                type="text"
                                placeholder="Search products..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                                className="search-input"
                            />
                            <p>{filteredAndSearchedProducts.length} products found {selectedCategory && <button onClick={handleResetClick} className="reset-search">Reset Search</button>}</p>
                            <ul className="products">
                                {filteredAndSearchedProducts.slice(0, visibleProducts).map(product => (
                                    <li key={product.product_id} className="product-box">
                                        <NavLink to={`/product/${product.product_id}`}>
                                            <img src={product.product_image} alt={product.product_title} />
                                            <h3>{product.product_title}</h3>
                                            <p>Starting From: AED {product.product_price}</p>
                                            <NavLink to={`/product/${product.product_id}`} className="select-btn">Select Options</NavLink>
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                            <br />
                            {visibleProducts < filteredAndSearchedProducts.length && (
                                <button onClick={loadMore} className="load_more">Load More</button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showGoUpButton && (
                <button onClick={scrollToTop} className="go-up-button">
                    Go Up
                </button>
            )}
        </>
    );
}

export default Shop;