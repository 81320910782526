import React from "react";
import birthdaycat from './assets/images/birthday-cakes.webp';
import signaturecat from './assets/images/signature-cakes.webp';
import occassionscat from './assets/images/occassions-cakes.webp';
import weddingcat from './assets/images/wedding-cakes.webp';
import { NavLink } from "react-router-dom";

function Cats() {
    return(
        <>
        <div className="padtop80 padbot80">   
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <NavLink to="/shop">
                            <div className="catbox">
                                <div className="catbox-inner">
                                    <h3><span>Birthday Cakes</span></h3>
                                    <p>Customize & Celebrate</p>
                                    <img src={birthdaycat} alt="birthday category" className="img-fluid" />
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-md-3">
                        <NavLink to="/shop">
                            <div className="catbox">
                                <div className="catbox-inner">
                                    <h3><span>Signature Cakes</span></h3>
                                    <p>Perfect Every Time</p>
                                    <img src={signaturecat} alt="birthday category" className="img-fluid" />
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-md-3">
                        <NavLink to="/shop">
                            <div className="catbox">
                                <div className="catbox-inner">
                                    <h3><span>Occasions Cakes</span></h3>
                                    <p>You Name it We Make it</p>
                                    <img src={occassionscat} alt="birthday category" className="img-fluid" />
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-md-3">
                        <NavLink to="/shop">
                            <div className="catbox">
                                <div className="catbox-inner">
                                    <h3><span>Wedding Cakes</span></h3>
                                    <p>You Imagine We Create</p>
                                    <img src={weddingcat} alt="birthday category" className="img-fluid" />
                                </div>
                            </div>
                        </NavLink>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <p className="explore"><NavLink className="nav-link btn" to="/shop">Explore All</NavLink></p>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default Cats;